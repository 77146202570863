import { Grid, Box, Typography } from '@mui/material'
import WrapperBlock from '@components/common/WrapperBlock'
import React from 'react'
import { useTranslation } from '@contexts/translation'

const NotFound = () => {
  React.useEffect(() => {
    if (document.getElementById('sideMenu')) {
      document.getElementById('sideMenu').style.display = 'none'
      return () => {
        document.getElementById('sideMenu').style.display = 'block'
      }
    }
  }, [])

  const { dictionary } = useTranslation()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <WrapperBlock
          title={null}
          description={null}
          preFilters={null}
          filters={null}
        >
          <Grid
            container
            xs={12}
            justifyContent="space-around"
            alignItems="center"
            minHeight
          >
            <Grid item xs={5}>
              <Box
                component="img"
                src={'/keysy_logo.png'}
                sx={{
                  objectFit: 'cover',
                  width: t => t.spacing(20),
                  marginBottom: 5
                }}
              />

              <Typography
                component="p"
                data-testid="Typography-4c389dec-6aa5-48b9-9f96-c2fb4c1b0ac0"
              >
                <Box
                  sx={{ fontWeight: 'bold', display: 'inline', paddingY: 2 }}
                >
                  404
                </Box>
              </Typography>
              <Typography
                component="p"
                sx={{ paddingY: 4 }}
                data-testid="Typography-b7b190b8-cb75-4d7e-9c4e-bfcfc11a0b7a"
              >
                <Box sx={{ paddingY: 2, display: 'inline' }}>
                  {dictionary.desc_404}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                component="img"
                src="/Placeholders/error-404.png"
                sx={{
                  objectFit: 'cover',
                  width: t => t.spacing(84)
                }}
              />
            </Grid>
          </Grid>
        </WrapperBlock>
      </Grid>
    </Grid>
  )
}

export default NotFound
